import React from 'react';
import {
  Box,
  Flex,
  Text,
  IconButton,
  Image,
  Stack,
  Link,
  useDisclosure,
  Button,
  Divider,
  useColorMode,
} from '@chakra-ui/react';

import {
  HamburgerIcon,
  CloseIcon,
  SunIcon,
  MoonIcon,
} from '@chakra-ui/icons';

import LoginModal from './LoginModal';
import { useAuth } from '../context/AuthContext';
import SuccessPopup from './SuccessPopup';
import { useState } from 'react';
import { useColors } from '../constants/constants.js';
import { Game } from '../config.js';

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  const { authState, logout, isAdmin } = useAuth();
  const [successLogout, setSuccessLogout] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const colors = useColors();  // Use the custom hook here

  const handleLogout = async () => {
    await logout();
    setSuccessLogout(true);
  };

  return (
    <>
      <SuccessPopup isOpen={successLogout} onClose={() => setSuccessLogout(false)} message="Vous êtes déconnecté !" />
      <Box>
        <Flex
          bg={colors.bgColor}
          color={colors.textColor}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={colors.borderColor}
          align={'center'}
        >
          <Flex flex={{ base: 1, md: 'auto' }} ml={{ base: -2 }} display={{ base: 'flex', md: 'none' }}>
            <IconButton
              onClick={onToggle}
              icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
              variant={'ghost'}
              aria-label={'Toggle Navigation'} />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <a href="/">
              <Image src="/abyss_writing.png" alt="logo" height={'4em'} />
            </a>

            <Flex ml={10} mt={3}>
              <DesktopNav />
            </Flex>
          </Flex>

          <Stack flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'} spacing={6}>
            <IconButton
              aria-label="Toggle Dark Mode"
              icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              onClick={toggleColorMode}
              variant="ghost"
            />
            {authState.student ? (
              <>
                <Text fontSize={'xl'} fontWeight={600} color={colors.textColor} whiteSpace={'nowrap'} mt={2} mr={2}>
                  Bonjour, {authState.student.username} !
                </Text>
                <Button
                  as={'a'}
                  display={{ base: 'none', md: 'inline-flex' }}
                  fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={colors.blueButton}
                  href={'/profile'}
                  _hover={{
                    bg: colors.blueButtonHover,
                  }}
                >
                  Profil
                </Button>
                {isAdmin && (
                  <Button
                    as={'a'}
                    display={{ base: 'none', md: 'inline-flex' }}
                    fontSize={'sm'}
                    fontWeight={600}
                    color={'white'}
                    bg={colors.pinkButton}
                    href={'/admin'}
                    _hover={{
                      bg: colors.pinkButtonHover,
                    }}
                  >
                    Admin
                  </Button>
                )}
                <Button
                  as={'a'}
                  display={{ base: 'none', md: 'inline-flex' }}
                  fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={colors.pinkButton}
                  onClick={handleLogout}
                  _hover={{
                    bg: colors.pinkButtonHover,
                  }}
                  cursor={'pointer'}
                >
                  Déconnexion
                </Button>
              </>
            ) : (
              <LoginModal />
            )}
          </Stack>
        </Flex>

        {/* <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse> */}
      </Box>
    </>
  );
}

const DesktopNav = () => {
  const colors = useColors(); // Use the custom hook here

  return (
    <Stack direction={'row'} spacing={3}>
      {NAV_ITEMS.map((navItem, index) => (
        <React.Fragment key={navItem.label}>
          <Link
            p={2}
            mt={3}
            href={navItem.href ?? '#'}
            fontSize={'sm'}
            fontWeight={600}
            color={colors.linkColor}
            _hover={{
              textDecoration: 'none',
              color: colors.linkHoverColor,
            }}
            _active={{
              color: colors.linkActiveColor,
            }}
          >
            {navItem.label}
          </Link>
          {index < NAV_ITEMS.length - 1 &&
            <Divider
              orientation="vertical"
              style={{ height: '30px', marginTop: '15px' }}
              borderColor={colors.textColor}
            />
          }
        </React.Fragment>
      ))}
    </Stack>
  );


  // return (
  //   <Stack direction={'row'} spacing={4}>
  //     {NAV_ITEMS.map((navItem) => (
  //       <Box key={navItem.label}>
  //         <Popover trigger={'hover'} placement={'bottom-start'}>
  //           <PopoverTrigger>
  //             <Link
  //               p={2}
  //               href={navItem.href ?? '#'}
  //               fontSize={'sm'}
  //               fontWeight={500}
  //               color={linkColor}
  //               _hover={{
  //                 textDecoration: 'none',
  //                 color: linkHoverColor,
  //               }}>
  //               {navItem.label}
  //             </Link>
  //           </PopoverTrigger>

  //           {navItem.children && (
  //             <PopoverContent
  //               border={0}
  //               boxShadow={'xl'}
  //               bg={popoverContentBgColor}
  //               p={4}
  //               rounded={'xl'}
  //               minW={'sm'}>
  //               <Stack>
  //                 {navItem.children.map((child) => (
  //                   <DesktopSubNav key={child.label} {...child} />
  //                 ))}
  //               </Stack>
  //             </PopoverContent>
  //           )}
  //         </Popover>
  //       </Box>
  //     ))}
  //   </Stack>
  // );
};

// const DesktopSubNav = ({ label, href, subLabel }) => {
//   return (
//     <Link
//       href={href}
//       role={'group'}
//       display={'block'}
//       p={2}
//       rounded={'md'}
//       _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
//       <Stack direction={'row'} align={'center'}>
//         <Box>
//           <Text
//             transition={'all .3s ease'}
//             _groupHover={{ color: 'pink.400' }}
//             fontWeight={500}>
//             {label}
//           </Text>
//           <Text fontSize={'sm'}>{subLabel}</Text>
//         </Box>
//         <Flex
//           transition={'all .3s ease'}
//           transform={'translateX(-10px)'}
//           opacity={0}
//           _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
//           justify={'flex-end'}
//           align={'center'}
//           flex={1}>
//           <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
//         </Flex>
//       </Stack>
//     </Link>
//   );
// };

// const MobileNav = () => {
//   return (
//     <Stack
//       bg={useColorModeValue('white', 'gray.800')}
//       p={4}
//       display={{ md: 'none' }}>
//       {NAV_ITEMS.map((navItem) => (
//         <MobileNavItem key={navItem.label} {...navItem} />
//       ))}
//     </Stack>
//   );
// };

// const MobileNavItem = ({ label, children, href }) => {
//   const { isOpen, onToggle } = useDisclosure();

//   return (
//     <Stack spacing={4} onClick={children && onToggle}>
//       <Flex
//         py={2}
//         as={Link}
//         href={href ?? '#'}
//         justify={'space-between'}
//         align={'center'}
//         _hover={{
//           textDecoration: 'none',
//         }}>
//         <Text
//           fontWeight={600}
//           color={useColorModeValue('gray.600', 'gray.200')}>
//           {label}
//         </Text>
//         {children && (
//           <Icon
//             as={ChevronDownIcon}
//             transition={'all .25s ease-in-out'}
//             transform={isOpen ? 'rotate(180deg)' : ''}
//             w={6}
//             h={6}
//           />
//         )}
//       </Flex>

//       <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
//         <Stack
//           mt={2}
//           pl={4}
//           borderLeft={1}
//           borderStyle={'solid'}
//           borderColor={useColorModeValue('gray.200', 'gray.700')}
//           align={'start'}>
//           {children &&
//             children.map((child) => (
//               <Link key={child.label} py={2} href={child.href}>
//                 {child.label}
//               </Link>
//             ))}
//         </Stack>
//       </Collapse>
//     </Stack>
//   );
// };

// interface NavItem {
//   label: string;
//   subLabel?: string;
//   children?: Array<NavItem>;
//   href?: string;
// }

const NAV_ITEMS = [
  // {
  //   label: 'Classement',
  //   href: '/classement',
  // },
  {
    label: 'Classement',
    href: '/leaderboard',
  },
  {
    label: 'Combats',
    href: '/fights'
  },
  {
    label: 'Palmarès',
    href: '/palmares'
  },
];

if (Game.rulesfile) {
  NAV_ITEMS.unshift({
    label: `Règles de ${Game.name ?? 'Jeu'}`,
    href: '/rules'
  });
}

/*     {
      label: 'Inspiration',
      children: [
        {
          label: 'Explore Design Work',
          subLabel: 'Trending Design to inspire you',
          href: '#',
        },
        {
          label: 'New & Noteworthy',
          subLabel: 'Up-and-coming Designers',
          href: '#',
        },
      ],
    },
*/
