import React, { useState, useEffect } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react';
import Loading from './Loading';

import { FightStatus } from '../constants/constants';
import FightHistory from './FightHistory';
import { useColors } from '../constants/constants';
import { getGroupCollection } from '../api/groups/groups.ts'

const FightPage = () => {
  const colors = useColors();
  const [tabIndex, setTabIndex] = useState(0);
  const [groups, setGroups] = useState(null);

  useEffect(() => {
    const fetchGroups = async() => {
      const response = await getGroupCollection(
        {include_stats: true},
        { headers: { "X-Fields": "id,name,agents{id,n_victories,n_defeats,n_points_conceded,n_points_scored}"}}
      );
      setGroups(response.reduce((acc, group) => {
        acc[group.id] = group;
        return acc;
      }, {}));
    }
    fetchGroups();
  }, [])

  useEffect(() => {
    const savedTabIndex = localStorage.getItem('fightTabIndex');
    if (savedTabIndex !== null) {
      setTabIndex(parseInt(savedTabIndex, 10));
    }
  }, []);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    localStorage.setItem('fightTabIndex', index);
  };
  
  if (groups === null) return <Loading />;
  return (
    <Box p={5} bg={colors.panelBg} minH="100vh" maxW="7xl" mx="auto" borderRadius="lg">
      <Tabs variant="soft-rounded" mt={10} index={tabIndex} onChange={handleTabsChange}>
        <TabList justifyContent={'center'} mb={10}>
          <Tab
            fontWeight="bold"
            bg={colors.tabBg}
            color={colors.tabColor}
            _selected={{ bg: colors.tabSelectedBg, color: colors.tabSelectedColor }}
            mr={2}
          >
            Historique
          </Tab>
          <Tab
            fontWeight="bold"
            bg={colors.tabBg}
            color={colors.tabColor}
            _selected={{ bg: colors.tabSelectedBg, color: colors.tabSelectedColor }}
            mr={2}
          >
            En cours
          </Tab>
          <Tab
            fontWeight="bold"
            bg={colors.tabBg}
            color={colors.tabColor}
            _selected={{ bg: colors.tabSelectedBg, color: colors.tabSelectedColor }}
            mr={2}
          >
            Prochains combats
          </Tab>
          <Tab
            fontWeight="bold"
            bg={colors.tabBg}
            color={colors.tabColor}
            _selected={{ bg: colors.tabSelectedBg, color: colors.tabSelectedColor }}
            mr={2}
          >
            Annulés
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <FightHistory status={FightStatus.DONE} groups={groups} selected={tabIndex === 0}/>
          </TabPanel>
          <TabPanel>
            <FightHistory status={FightStatus.IN_PROGRESS} groups={groups} selected={tabIndex === 1}/>
          </TabPanel>
          <TabPanel>
            <FightHistory status={FightStatus.SCHEDULED} groups={groups} selected={tabIndex === 2}/>
          </TabPanel>
          <TabPanel>
            <FightHistory status={FightStatus.CANCELLED} groups={groups} selected={tabIndex === 3}/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default FightPage;
