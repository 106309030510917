import React from 'react';
import { Game } from '../config';
import { Heading, Box, Image, Flex, Text } from '@chakra-ui/react';
import { useColors } from '../constants/constants';

const RulesDisplay = () => {
    const colors = useColors();
    if (Object.keys(Game).length === 0) {
        return <Heading as="h1" size="xl" textAlign="center" mt={5} mb={5} color={colors.cyanTextColor}>Aucun jeu n&apos;est configuré</Heading>
    }
    return (
        <>
            <Heading as="h1" size="xl" textAlign="center" mt={5} mb={5} color={colors.cyanTextColor}>Règles du jeu {Game.name}</Heading>
            <Heading as="h2" size="lg" textAlign="center" mt={5} mb={5} color={colors.pinkTextColor}>{Game.description}</Heading>
            {/* <iframe src={Game.rulesfile} style={{ width: '100%', height: '100vh', border: 'none' }} title="Rules"></iframe> */}
            <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                {Game.rulesPages.map((page, index) => (
                    <Box
                        maxW="900px"
                        p="4"
                        borderWidth="1px"
                        borderRadius="lg"
                        boxShadow="2xl"
                        borderColor="gray.400"
                        bg="white"
                        overflow="hidden"
                        key={index}
                        m={5}
                    >
                        <Image
                            src={page}
                            alt={`Page ${index + 1}`}
                            borderRadius="md"
                            objectFit="cover"
                        />
                    </Box>
                ))}

            </Flex>
            <Text textAlign="center" mt={5} mb={5} color='gray.500'>Source : 
                <Text as="a" href={Game.copyright} color='teal.500' target="_blank" rel="noreferrer"> {Game.copyright}</Text>
            </Text>
        </>
    );
};

export default RulesDisplay;