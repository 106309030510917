import PropTypes from 'prop-types';
import { useToast } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 

const ProtectedRoute = ( {element} ) => {
    const toast = useToast()
    const { authState, wasConnected } = useAuth();

    if (authState.student != null) {
        return element;
    }

    if (wasConnected) {
        toast({
            title: "Session expirée.",
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    }
    return <Navigate to="/" />;
};

ProtectedRoute.propTypes = {
    element: PropTypes.element
}

export default ProtectedRoute;
