import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalHeader, Text, Flex, Box, useColorModeValue } from '@chakra-ui/react';

const ErrorPopup = ( {isOpen, onClose, message} ) => {
  const headerColor = useColorModeValue('red.500', 'red.300');
  const iconColor = useColorModeValue('red', '#FF7F7F');
  const textColor = useColorModeValue('black', 'white');

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose(); // Automatically close the modal after 2 seconds
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" color={headerColor}>Échec !</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" alignItems="center" justifyContent="center" textAlign="center">
            <Box mb="4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke={iconColor} width="64px" height="64px">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </Box>
            <Text mb="4" color={textColor}>{message}</Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ErrorPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
}

export default ErrorPopup;
