import React from 'react';
import PropTypes from 'prop-types';
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button } from '@chakra-ui/react';

const AlertVerification = ( {isDialogOpen, setIsDialogOpen, handleDelete, name} ) => {
    const cancelRef = React.useRef();


    return (
        <AlertDialog
            isOpen={isDialogOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => setIsDialogOpen(false)}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>Confirmer la suppression</AlertDialogHeader>
                    <AlertDialogBody>
                        Êtes-vous sûr de vouloir supprimer {name} ?
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={() => setIsDialogOpen(false)}>
                            Annuler
                        </Button>
                        <Button colorScheme="pink" onClick={handleDelete} ml={3}>
                            Supprimer
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

AlertVerification.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    setIsDialogOpen: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired, 
    name: PropTypes.string
}

export default AlertVerification;