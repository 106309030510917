import React from 'react';
import Hero from './Hero';
//import LeaderBoard from './Leaderboard';


function Home() {
  return (
      <><Hero /></>
  );
}

export default Home;
