import React from 'react';
import PropTypes from 'prop-types';

import { Input, Box} from '@chakra-ui/react';

const SearchBar = ({ searchTerm, setSearchTerm }) => {
    const handleChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
    };

    return (
        <Box>
            <Input
                placeholder="Rechercher par groupe"
                value={searchTerm}
                onChange={handleChange}
                mb={4}
            />
        </Box>
    );
};

SearchBar.propTypes = {
    searchTerm: PropTypes.string,
    setSearchTerm: PropTypes.func
}

export default SearchBar;
