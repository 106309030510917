import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Heading, Text, Image, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
} from '@chakra-ui/react';
import GroupCard from './GroupCard';
import { LeagueLogos, capitalize, useLeagueColors } from '../constants/constants';


const LeagueAccordionItem = ( { league, index, leagueGroups, textColor } ) => {
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleGroupClick = (group) => {
    setSelectedGroup(selectedGroup === group ? null : group);
  };

  //const numGroups = leagueGroups.length;
  const minElo = leagueGroups.length > 0 ? leagueGroups[leagueGroups.length - 1].elo : '';
  const maxElo = leagueGroups.length > 0 ? leagueGroups[0].elo : '';
  const leagueColors = useLeagueColors();
  return (
    <AccordionItem>
      <AccordionButton borderRadius={"3xl"}>
        <Box
          flex="1"
          textAlign="left"
          display="flex"
          alignItems="center"
          p={4}
          borderRadius="3xl"
          bgColor={leagueColors[league]}
          backgroundSize="cover"
          backgroundPosition="center"
          border="2px solid teal"
          boxShadow="lg"
          _hover={{ transform: 'scale(1.01)', boxShadow: 'xl' }}
        >
          <Image src={LeagueLogos[league]} alt={`${league} logo`} boxSize="120px" mr={4} />
          <Box flex="1">
            <Heading size="lg" color={"black"} mb={2} textAlign={'center'}>
              Ligue {capitalize(league)}
            </Heading>
            <Box textAlign={'center'}>
              {/* <Text color={textColor}>{numGroups} groupes</Text> */}
              {minElo !== '' && maxElo !== '' &&
                <Text as={'b'} color={'black'}>{minElo}-{maxElo} Elo</Text>
              }
            </Box>
          </Box>
          <Image src={LeagueLogos[league]} alt={`${league} logo`} boxSize="120px" mr={4} />
          <AccordionIcon />
        </Box>
      </AccordionButton>
      <AccordionPanel pb={4} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        {leagueGroups.map((group) => (
          <Box key={group.id} w="90%">
            <GroupCard
              parentIndex={index}
              group={group}
              selectedGroup={selectedGroup}
              handleGroupClick={handleGroupClick}
              boxSize="small"
            />
          </Box>
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

LeagueAccordionItem.propTypes = {
  league: PropTypes.string.isRequired,
  index: PropTypes.number,
  leagueGroups: PropTypes.array.isRequired,
  textColor: PropTypes.string
}

export default LeagueAccordionItem;
