import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Tr, Td, Switch, IconButton
} from '@chakra-ui/react';
import StatusIconWithMessage from './StatusIcon.js';
import { DeleteIcon } from '@chakra-ui/icons';
import { putAgentToggleActive } from '../api/agents/agents.ts';
import { AgentStatus } from '../constants/constants.js';
import { useAuth } from '../context/AuthContext.jsx';

const AgentItem = ( {agent, setErrorMessage, setShowError, setIsDialogOpen, setAgent} ) => {
  const [isActive, setIsActive] = useState(agent.status === AgentStatus.ACTIVE);
  const { authState } = useAuth();

  const toggleActiveStatus = async () => {
    try {
      const updatedStatus = !isActive ? AgentStatus.ACTIVE : AgentStatus.INACTIVE;
      const response = await putAgentToggleActive(agent.id, authState.options);
      setIsActive(response.status === AgentStatus.ACTIVE);
      if (updatedStatus !== response.status) {
        setErrorMessage('Vous ne pouvez pas activer cet agent');
        setShowError(true);
      }
    } catch (error) {
      setErrorMessage('Erreur lors de la mise à jour du statut de l\'agent');
      setShowError(true);
      //console.error('Error updating agent status:', error);
    }
  };

  return (
    <>
      
      <Tr>
        <Td textAlign="center">
          <StatusIconWithMessage status={agent.status} errorInfo={agent.refusal_message} />
        </Td>
        <Td textAlign="center" fontSize={'lg'}>{agent.name}</Td>
        <Td textAlign="center" fontSize={'lg'}>{agent.n_victories}V-{agent.n_defeats}D</Td>
        <Td textAlign="center" fontSize={'lg'}>{agent.elo}</Td>
        <Td textAlign="center" fontSize={'lg'}>
          <Switch isChecked={isActive} colorScheme="pink" onChange={toggleActiveStatus} />
        </Td>
        <Td textAlign="center">
          <IconButton
            icon={<DeleteIcon />}
            colorScheme="pink"
            onClick={() => { 
              setIsDialogOpen(true);
              setAgent(agent);
            }}
            aria-label="Delete agent"
          />
        </Td>
      </Tr>
    </>
  );
};

AgentItem.propTypes = {
  agent: PropTypes.object.isRequired, 
  setErrorMessage: PropTypes.func.isRequired, 
  setShowError: PropTypes.func.isRequired, 
  setIsDialogOpen: PropTypes.func.isRequired, 
  setAgent: PropTypes.func.isRequired
}

export default AgentItem;
