import { useState, useEffect } from 'react';
import { Text } from '@chakra-ui/react';
import { useColors } from '../constants/constants';

function LoadingDots() {
  const [dots, setDots] = useState('');
  const colors = useColors();
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Text fontSize="2xl" color={colors.blackTextColor} fontWeight="bold">
      En cours{dots}
    </Text>
  );
}

export default LoadingDots;
