import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Text, VStack, Button, Center } from '@chakra-ui/react';
import { getFightCollection } from '../api/fights/fights.ts';
import FightCard from './FightCard.js';
import Loading from './Loading.js';
import ErrorMessage from './ErrorMessage.js';
import SearchBar from './SearchBar.js';
import { FightStatus, useColors } from '../constants/constants.js';
import { useColorModeValue } from '@chakra-ui/react';

const FightHistory = ({ status, groups, selected }) => {
    const [fights, setFights] = useState([]);
    const [page, setPage] = useState(1);
    const [prevPage, setPrevPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFights, setFilteredFights] = useState([]);

    const textColor = useColorModeValue('teal.500', 'teal.300');
    const buttonColorScheme = useColorModeValue('pink', 'purple');
    const colors = useColors();

    
    useEffect(() => {
        const fetchFights = async (page, limit) => {
            try {
                setLoading(true);
                const params = {
                  page: page,
                  limit: limit,
                  status: status
                }

                const response = await getFightCollection(
                  params, 
                  { headers: { "X-Fields": "id,date,scheduled_date,score_player1,score_player2," +
                    "player1{name,group{id,name},elo,id,custom_stats{name,value}}," +
                    "player2{name,group{id,name},elo,id,custom_stats{name,value}}" } }
                );
                const sort_response = response.sort((a, b) => status === FightStatus.SCHEDULED ? (new Date(a.scheduled_date) - new Date(b.scheduled_date)) :
                                                     (new Date(b.date) - new Date(a.date)));
                if (sort_response.length === 0) {
                    setHasMore(false);
                } else {
                    if (page > prevPage) setFights((prevFights) => [...prevFights, ...response]);
                    else setFights(response);
                }
            } catch (error) {
                setError('Erreur lors du chargement des données');
                setHasMore(false);
            } finally {
                setLoading(false);
            }
        };

        if (selected) {
          fetchFights(page, 20);
          setPrevPage(page);
        } else {
          setPage(1);
          setPrevPage(page);
          setHasMore(true);
        }
    }, [page, status, selected]);

    useEffect(() => {
        setFilteredFights(
            fights.filter((fight) =>
                fight.player1.group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                fight.player2.group.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, fights]);

    if (error) {
        return <ErrorMessage message={error} />;
    }

    return (
        <Box minHeight="100vh" maxW={'7xl'} bg={colors.panelBg}>
          <Box maxW={'5xl'} mx="auto" >
          <Text fontSize="4xl" fontWeight="bold" textAlign="center" mb={6} color={textColor}>
            {status === FightStatus.DONE ? 'Historique des Combats' :
              status === FightStatus.SCHEDULED ? 'Prochains Combats' :
              status === FightStatus.IN_PROGRESS ? 'Combats en Cours' :
              'Combats Annulés'}
          </Text>
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <VStack spacing={6}>
            {filteredFights.map((fight) => (
              <FightCard fightData={fight} status={status} key={fight.id} groupData={groups} />
            ))}
          </VStack>
          {loading && <Loading />}
          {hasMore && !loading && (
            <Center>
              <Button
                mt={4}
                colorScheme={buttonColorScheme}
                onClick={() => setPage((oldPage) => oldPage + 1)}
              >
                Afficher plus
              </Button>
            </Center>
          )}
          </Box>
        </Box>
      );
    };
    
    FightHistory.propTypes = {
      status: PropTypes.oneOf([FightStatus.CANCELLED, FightStatus.DONE, FightStatus.IN_PROGRESS, FightStatus.SCHEDULED]), 
      groups: PropTypes.object.isRequired,
      selected: PropTypes.bool.isRequired
    }

    export default FightHistory;
