import React from 'react';
import PropTypes from 'prop-types';

import { Button, Flex } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/react';


const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const bgColor = useColorModeValue('gray.200', 'gray.600');
    const hoverBgColor = useColorModeValue('gray.300', 'gray.800');
    const activeBgColor = useColorModeValue('teal.500', 'teal.300');
    const textColor = useColorModeValue('black', 'white');
  
    const handleClick = (page) => {
      if (page >= 0 && page < totalPages) {
        onPageChange(page);
      }
    };
  
    const renderPageButtons = () => {
      const pages = [];
  
      if (totalPages <= 7) {
        // Show all pages if there are 7 or fewer
        for (let i = 0; i < totalPages; i++) {
          pages.push(
            <Button
              key={i}
              onClick={() => handleClick(i)}
              bg={currentPage === i ? activeBgColor : bgColor}
              color={currentPage === i ? 'white' : textColor}
              _hover={{ bg: currentPage === i ? activeBgColor : hoverBgColor }}
              mx={1}
            >
              {i + 1}
            </Button>
          );
        }
      } else {
        // Show first 4 pages, last 3 pages, and ellipsis if there are more than 7 pages
        for (let i = 0; i < 4; i++) {
          pages.push(
            <Button
              key={i}
              onClick={() => handleClick(i)}
              bg={currentPage === i ? activeBgColor : bgColor}
              color={currentPage === i ? 'white' : textColor}
              _hover={{ bg: hoverBgColor }}
              mx={1}
            >
              {i + 1}
            </Button>
          );
        }
  
        if (currentPage > 4 && currentPage < totalPages - 3) {
          pages.push(
            <Button key="ellipsis" disabled bg={bgColor} color={textColor} mx={1}>
              ...
            </Button>
          );
          pages.push(
            <Button
              key={currentPage}
              onClick={() => handleClick(currentPage)}
              bg={activeBgColor}
              color="white"
              _hover={{ bg: hoverBgColor }}
              mx={1}
            >
              {currentPage + 1}
            </Button>
          );
          pages.push(
            <Button key="ellipsis2" disabled bg={bgColor} color={textColor} mx={1}>
              ...
            </Button>
          );
        } else {
          pages.push(
            <Button key="ellipsis" disabled bg={bgColor} color={textColor} mx={1}>
              ...
            </Button>
          );
        }
  
        for (let i = totalPages - 3; i < totalPages; i++) {
          pages.push(
            <Button
              key={i}
              onClick={() => handleClick(i)}
              bg={currentPage === i ? activeBgColor : bgColor}
              color={currentPage === i ? 'white' : textColor}
              _hover={{ bg: hoverBgColor }}
              mx={1}
            >
              {i + 1}
            </Button>
          );
        }
      }
  
      return pages;
    };
  
    return (
      <Flex justifyContent="center" p={4}>
        <Button
          onClick={() => handleClick(currentPage - 1)}
          disabled={currentPage === 0}
          bg={bgColor}
          color={textColor}
          _hover={{ bg: hoverBgColor }}
          mx={1}
        >
          Précédent
        </Button>
        {renderPageButtons()}
        <Button
          onClick={() => handleClick(currentPage + 1)}
          disabled={currentPage === totalPages - 1}
          bg={bgColor}
          color={textColor}
          _hover={{ bg: hoverBgColor }}
          mx={1}
        >
          Suivant
        </Button>
      </Flex>
    );
  };


export default Pagination;

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
}