import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Loading from './Loading';
import Pagination from './Pagination';
import FightCard from './FightCard';
import { Box, HStack, VStack, Button } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import ErrorPopup from './ErrorPopup';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { FightStatus, useColors } from '../constants/constants.js';
import { getFightItemJson } from '../api/fights/fights.ts';
import { getFightGroup, getCountFightGroup } from '../api/fights/fights.ts';
import { useAuth } from '../context/AuthContext.jsx';


const FightList = ({ myAgents, status, groups, selected }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [fights, setFights] = useState(null)
  const fightsPerPage = 4;
  const [totalFights, setTotalFights] = useState(-1)
  const colors = useColors();
  const { authState } = useAuth();

  const downLoadJson = async (fightId) => {
    try {
      const response = await getFightItemJson(fightId);
      const blob = new Blob([JSON.stringify(response)], { type: 'application/json' });
      saveAs(blob, `fight_${fightId}.json`);
    } catch (error) {
      handleShowError();
    }
  };

  const downloadAllJson = async () => {
    const zip = new JSZip();
    const responseFights = await getFightGroup(authState.student.group_id, {status: "done" });
    
    const fightPromises = responseFights.map(async (fight) => {
      try {
        const response = await getFightItemJson(fight.id);
        zip.file(`fight_${fight.id}.json`, JSON.stringify(response));
      } catch (error) {undefined}
    });

    await Promise.all(fightPromises);

    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        saveAs(content, 'fights.zip');
      })
      .catch(handleShowError);
  };

  const fetchFights = async () => {
    getFightGroup(authState.student.group_id, {paginate: "true", page:currentPage+1, limit: fightsPerPage, status: status })
    .then((response) => setFights(response))
    .catch(() => undefined);
  }

  const countTotalFights = async () => {
    getCountFightGroup(authState.student.group_id, { status: status })
    .then((response) => setTotalFights(response))
    .catch(() => undefined);
  }

  const handleShowError = () => {
    setShowError(true);
    setErrorMessage('Erreur lors du téléchargement du fichier JSON');
  };

  const handleCloseError = () => {
    setShowError(false);
    setErrorMessage('');
  };

  const handlePageClick = (selectedPage) => {
    if (currentPage != selectedPage) {
      setCurrentPage(selectedPage);
      fetchFights();
    }
  };

  useEffect(() => {
    if (selected) {
      fetchFights();
      countTotalFights();
    }
  }, [selected])

  if (!fights || totalFights < 0) return <Loading />;

  return (
    <>
      <ErrorPopup message={errorMessage} onClose={handleCloseError} isOpen={showError} />
      <Box padding={4} bgColor={colors.panelBg} maxW="5xl" margin="auto" borderRadius="lg" boxShadow="xl">
        <VStack spacing={4} width="100%" mb={4}>
          {fights.map(fight => (
            <HStack key={fight.id} width="100%" alignItems="center">
              <Box flex="1">
                <FightCard fightData={fight} myAgents={myAgents} status={status} groupData={groups}/>
              </Box>
              {status === FightStatus.DONE && (
                <Box>
                  <DownloadIcon onClick={() => downLoadJson(fight.id)} cursor="pointer" w={5} h={5} ml={2} color="teal.500" />
                </Box>
              )}
            </HStack>
          ))}
        </VStack>
        {status === FightStatus.DONE && (
          <HStack justifyContent="space-between" alignItems="center">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalFights / fightsPerPage)}
              onPageChange={handlePageClick}
            />
            <Button leftIcon={<DownloadIcon />} colorScheme="teal" onClick={downloadAllJson}>
              Tout Télécharger
            </Button>
          </HStack>
        )}
      </Box>
    </>
  );
};

export default FightList;


FightList.propTypes = {
  myAgents: PropTypes.array.isRequired,
  status: PropTypes.oneOf([FightStatus.CANCELLED, FightStatus.DONE, FightStatus.IN_PROGRESS, FightStatus.SCHEDULED]).isRequired,
  groups: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired
}
