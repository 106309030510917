import React from 'react';
import { PropTypes } from 'prop-types';

import { Box, Text, Badge, Grid, GridItem, Heading } from '@chakra-ui/react';
import { useColors } from '../constants/constants.js';

const AgentGrid = ({ agents }) => {
  const colors = useColors();

  return (
    <Box mt={4}>
      <Heading as="h4" size="sm" color={colors.darkTextColor} mb={2} textAlign={'center'}>
        Agents
      </Heading>
      {agents.map((agent, idx) => (
        <Grid
          key={idx}
          templateColumns="repeat(4, 1fr)"
          gap={4}
          alignItems="center"
          mb={2}
          p={2}
          borderWidth="1px"
          borderRadius="md"
        >
          <GridItem>
            <Text as="b" color={colors.darkTextColor}> {agent.name} </Text>
          </GridItem>
          <GridItem>
            <Badge colorScheme="blue">ELO : {agent.elo}</Badge>
          </GridItem>
          <GridItem>
            <Badge colorScheme="green">Victoires : {agent.n_victories}</Badge>
          </GridItem>
          <GridItem>
            <Badge colorScheme="red">Défaites : {agent.n_defeats}</Badge>
          </GridItem>
        </Grid>
      ))}
    </Box>
  );
}

AgentGrid.propTypes = {
  agents: PropTypes.array.isRequired
}

export default AgentGrid;
