
export const Game = {
    name: 'Divercité',
    description : 'Un jeu de stratégie et de réflexion',
    rulesfile: '/divercite/regles_divercite.pdf',
    rulesPages : ['/divercite/regles_page1.jpg',
                 '/divercite/regles_page2.jpg',],
    copyright : "https://gregoire.boulland.org/divercite.html",
    frontURL : '/divercite/GUI/divercite.html',

}

export const CustomStatsInfos = [
    {
        title: 'Divercités',
        key: 'n_divercities',
        description: 'Le nombre de diversités',
        maximizable: true,
        in_palmares: true,
        palmares_image: 'juggle.png',

    }
]