import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie'
import { deleteStudentLogout, postStudentLogin, postStudentConnected } from '../api/students/students.ts';
import ErrorPopup from '../components/ErrorPopup.jsx';
import SuccessPopup from '../components/SuccessPopup.jsx';
import { getStudentAdminCheck } from '../api/students/students.ts';
import Loading from '../components/Loading.js';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    //const authEnabled = process.env.REACT_APP_AUTH_ENABLED === 'true';
    const [hasLoaded, setLoaded] = useState(false);
    const [authState, setAuthState] = useState({student: null});
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [isAdmin, setIsAdmin] = useState(null);

    useEffect(() => {
        const initialAuthState = async () => {
            try {
                const options = {
                    credentials: "same-origin",
                    headers: {
                        "X-CSRF-TOKEN": Cookies.get("csrf_access_token")
                    }
                };
                const response = await postStudentConnected(options);
                if (response.connected) {
                    return {student: response.identity, options: options}
                } else {
                    Cookies.remove('csrf_access_token')
                    return {student: null, options: null}
                }
            } catch {
                Cookies.remove('csrf_access_token')
                return {student: null, options: null}
            }
        };
        
        if (!hasLoaded) {
            initialAuthState().then((state) => {
                setAuthState(state);
            }).finally(() => {
                setLoaded(true);
            });
        }
    }, [authState, setAuthState, setLoaded, hasLoaded]);

    useEffect(() => {
        const checkAdmin = async () => {
            const response = await getStudentAdminCheck(authState.options);
            return response.is_admin;
        };
        if (hasLoaded) {
            if (authState.student != null && authState.student.role === "admin") {
                checkAdmin().then((admin) => {
                    setIsAdmin(admin);
                }).catch((error) => {
                    setIsAdmin(false);
                    console.error('Error checking admin status:', error);
                });
            } else {
                setIsAdmin(false);
            }
        }
    }, [authState.student, authState.options, isAdmin, hasLoaded]);

    const login = (student_id, password) => {
        postStudentLogin({ id: student_id, password: password }).then((response) => {
            const options = {
                credentials: "same-origin",
                headers: {
                    "X-CSRF-TOKEN": Cookies.get('csrf_access_token')
                }
            };
            setAuthState({student: response.identity, options: options})

            setErrorMessage('');
            setShowError(false);
            setSuccessMessage('Connexion réussie');
            setShowSuccess(true);
        }).catch(() => {
            setErrorMessage('Identifiants invalides');
            setShowError(true);
        });
    };

    const logout = useCallback(() => {
        deleteStudentLogout(authState.options);
        setAuthState({student: null, options: null});
        setIsAdmin(false);
    }, [authState.options]);

    if (!hasLoaded) return <Loading />;

    return (
        <AuthContext.Provider value={{ authState, login, logout, isAdmin }}>
            <ErrorPopup message={errorMessage} onClose={() => setShowError(false)} isOpen={showError} />
            <SuccessPopup message={successMessage} onClose={() => setShowSuccess(false)} isOpen={showSuccess} />
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.element.isRequired
}

export const useAuth = () => useContext(AuthContext);
