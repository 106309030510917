import React from 'react';
import { useLocation } from 'react-router-dom';
import { Game } from '../config';

const FightDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const url = `${Game.frontURL}?fightId=${id}`;

  return (
    <iframe src={url} style={{ width: '100%', height: '100vh', border: 'none' }} title="Fight Details"></iframe>
  );
};

export default FightDetails;
