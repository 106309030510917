/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Abyss API
 * API for the Abyss project
 * OpenAPI spec version: 0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Arena
} from '.././model'
import { axios_instance } from '.././mutator/axios-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getArenaState = (
    
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<Arena>(
      {url: `https://abyss-a24.corail-lab.ca/api/arena`, method: 'GET', signal
    },
      options);
    }
  

export const getGetArenaStateQueryKey = () => {
    return [`https://abyss-a24.corail-lab.ca/api/arena`] as const;
    }

    
export const getGetArenaStateQueryOptions = <TData = Awaited<ReturnType<typeof getArenaState>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getArenaState>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetArenaStateQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getArenaState>>> = ({ signal }) => getArenaState(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getArenaState>>, TError, TData> & { queryKey: QueryKey }
}

export type GetArenaStateQueryResult = NonNullable<Awaited<ReturnType<typeof getArenaState>>>
export type GetArenaStateQueryError = unknown

export const useGetArenaState = <TData = Awaited<ReturnType<typeof getArenaState>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getArenaState>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetArenaStateQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const patchArenaState = (
    arena: Arena,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<Arena>(
      {url: `https://abyss-a24.corail-lab.ca/api/arena`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: arena
    },
      options);
    }
  


export const getPatchArenaStateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchArenaState>>, TError,{data: Arena}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchArenaState>>, TError,{data: Arena}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchArenaState>>, {data: Arena}> = (props) => {
          const {data} = props ?? {};

          return  patchArenaState(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchArenaStateMutationResult = NonNullable<Awaited<ReturnType<typeof patchArenaState>>>
    export type PatchArenaStateMutationBody = Arena
    export type PatchArenaStateMutationError = unknown

    export const usePatchArenaState = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchArenaState>>, TError,{data: Arena}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchArenaState>>,
        TError,
        {data: Arena},
        TContext
      > => {

      const mutationOptions = getPatchArenaStateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    