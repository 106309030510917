import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Input,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    HStack,
    IconButton,
    useToast,
    Select
} from '@chakra-ui/react';
import { EditIcon, CheckIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { getFightCollection, patchFightItem, deleteFightItem, getCountFights } from '../api/fights/fights.ts';
import { getAgentCollection } from '../api/agents/agents.ts';
import { CustomStatsInfos } from '../config.js';
import { FightStatus, useColors } from '../constants/constants.js';
import { useAuth } from '../context/AuthContext.jsx';
import Pagination from './Pagination.js';

const FightPanel = ({ selected }) => {
    const [fights, setFights] = useState([]);
    const [totalFights, setTotalFights] = useState(-1);
    const fightsPerPage = 500;

    const [editFightId, setEditFightId] = useState(null);
    const [editFightData, setEditFightData] = useState({});
    const [agents, setAgents] = useState({});

    const [currentPage, setCurrentPage] = useState(0);

    const toast = useToast();
    const colors = useColors();    
    const { authState } = useAuth();

    const fetchAgents = async () => {
        try {
            const response = await getAgentCollection(
                {},
                { headers: { "X-Fields": "id,group{name},name"}}
            );
            const agentsData = response.reduce((acc, agent) => {
                acc[agent.id] = agent;
                return acc;
            }, {});
            setAgents(agentsData);
        } catch (error) {
            toast({
                title: "Error fetching agents.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const fetchFights = async () => {
        try {
            const response = await getFightCollection(
                { paginate: true, page: currentPage+1, limit: fightsPerPage},
                { headers: { "X-Fields": "id,player1_id,player2_id,winner_id,status,score_player1,score_player2,custom_stats"}}
            );
            setFights(response);
        } catch (error) {
            toast({
                title: "Error fetching fights.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleUpdate = async (id) => {
        try {
            await patchFightItem(id, editFightData, authState.options);
            setEditFightId(null);
            fetchFights();
            toast({
                title: "Fight updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error updating fight.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteFightItem(id, authState.options);
            fetchFights();
            toast({
                title: "Fight deleted.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error deleting fight.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const countTotalFights = async () => {
        try {
            const response = await getCountFights();
            setTotalFights(response);
        } catch (error) {
            toast({
                title: "Error fetching agents.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        if (selected) {
            fetchAgents();
            fetchFights();
            countTotalFights();
        }
    }, [selected]);

    const startEditing = (fight) => {
        const { player1, player2, group, ...sFight } = fight;
        setEditFightId(fight.id);
        setEditFightData(sFight);
    };

    const cancelEditing = () => {
        setEditFightId(null);
        setEditFightData({});
    };

    const confirmEditing = async () => {
        await handleUpdate(editFightId);
    };

    const handlePageClick = (selectedPage) => {
        if (currentPage != selectedPage) {
          setCurrentPage(selectedPage);
          fetchAgents();
          fetchFights();
        }
     };

    return (
        <Box p={5} bg={colors.panelBg} borderRadius="md" mx="auto" w="80%" boxShadow="lg">
            <HStack justifyContent="space-between" alignItems="center">
                <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(totalFights / fightsPerPage)}
                onPageChange={(index) => handlePageClick(index)}
                />
            </HStack>
            <Table variant="striped" bgColor={colors.tabBg} mt={5} borderRadius="md" boxShadow="md">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Statut</Th>
                        <Th>Agent 1</Th>
                        <Th>Agent 2</Th>
                        <Th>Gagnant</Th>
                        <Th>Score Agent 1</Th>
                        <Th>Score Agent 2</Th>
                        {CustomStatsInfos.map((info) => (
                            <Fragment key={info.key}>
                                <Th key={info.key + '1'}>{info.title} Agent 1</Th>
                                <Th key={info.key + '2'}>{info.title} Agent 2</Th>
                            </Fragment>
                        ))}
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {fights.map((fight) => (
                        <Tr key={fight.id}>
                            <Td fontWeight="bold">{fight.id}</Td>
                            <Td>
                                {editFightId === fight.id ? (
                                    <Select
                                        value={editFightData.status}
                                        onChange={(e) => setEditFightData({ ...editFightData, status: e.target.value })}
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    >
                                        {Object.keys(FightStatus).map((key) => (
                                            <option key={key} value={FightStatus[key]}>{key}</option>
                                        ))}
                                    </Select>
                                ) : (
                                    fight.status.toUpperCase()
                                )}
                            </Td>
                            <Td>
                                {editFightId === fight.id ? (
                                    <Select
                                        value={editFightData.player1_id}
                                        onChange={(e) => setEditFightData({ ...editFightData, player1_id: e.target.value })}
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    >
                                        {Object.keys(agents).map((key) => (
                                            <option key={key} value={key}>{agents[key]?.group.name} : {agents[key]?.name}</option>
                                        ))}
                                    </Select>
                                ) : (
                                    <>
                                        <Box as="span" fontWeight="bold">{agents[fight.player1_id]?.name}</Box>
                                        <Box as="span" display="block">{agents[fight.player1_id]?.group.name}</Box>
                                    </>
                                )}
                            </Td>

                            <Td>
                                {editFightId === fight.id ? (
                                    <Select
                                        value={editFightData.player2_id}
                                        onChange={(e) => setEditFightData({ ...editFightData, player2_id: e.target.value })}
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    >
                                        {Object.keys(agents).map((key) => (
                                            <option key={key} value={key}>{agents[key]?.group.name} : {agents[key]?.name}</option>
                                        ))}
                                    </Select>
                                ) : (
                                    <>
                                        <Box as="span" fontWeight="bold">{agents[fight.player2_id]?.name}</Box>
                                        <Box as="span" display="block">{agents[fight.player2_id]?.group.name}</Box>
                                    </>
                                )}
                            </Td>
                            <Td>
                                {editFightId === fight.id ? (
                                    <Select
                                        value={editFightData.winner_id}
                                        onChange={(e) => setEditFightData({ ...editFightData, winner_id: parseInt(e.target.value) })}
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    >
                                        <option value="">Aucun</option>
                                        {editFightData.player1_id && (
                                            <option value={editFightData.player1_id}>
                                                {agents[editFightData.player1_id]?.name || ''}
                                            </option>
                                        )}
                                        {editFightData.player2_id && (
                                            <option value={editFightData.player2_id}>
                                                {agents[editFightData.player2_id]?.name || ''}
                                            </option>
                                        )}
                                    </Select>
                                ) : (
                                    agents[fight.winner_id]?.name || ''
                                )}
                            </Td>
                            <Td>
                                {editFightId === fight.id ? (
                                    <Input
                                        value={editFightData.score_player1}
                                        onChange={(e) => setEditFightData({ ...editFightData, score_player1: e.target.value })}
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    />
                                ) : (
                                    fight.score_player1
                                )}
                            </Td>
                            <Td>
                                {editFightId === fight.id ? (
                                    <Input
                                        value={editFightData.score_player2}
                                        onChange={(e) => setEditFightData({ ...editFightData, score_player2: e.target.value })}
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    />
                                ) : (
                                    fight.score_player2
                                )}
                            </Td>
                            {CustomStatsInfos.map((info) => (
                                <React.Fragment key={info.key}>
                                    <Td>
                                        {editFightId === fight.id ? (
                                            <Input
                                                value={editFightData.custom_stats?.find((stat) => stat.name === info.key && stat.agent_id === fight.player1_id)?.value ?? ''}
                                                onChange={(e) =>
                                                    setEditFightData({
                                                        ...editFightData,
                                                        custom_stats: editFightData.custom_stats?.some(stat => stat.name === info.key && stat.agent_id === fight.player1_id) ?
                                                            editFightData.custom_stats.map((stat) => {
                                                                if (stat.name === info.key && stat.agent_id === fight.player1_id) {
                                                                    return { ...stat, value: e.target.value };
                                                                } else {
                                                                    return stat;
                                                                }
                                                            }) :
                                                            [
                                                                ...editFightData.custom_stats,
                                                                {
                                                                    name: info.key,
                                                                    agent_id: fight.player1_id,
                                                                    value: e.target.value
                                                                }
                                                            ]
                                                    })
                                                }
                                                borderColor="cyan.600"
                                                focusBorderColor="cyan.400"
                                            />
                                        ) : (
                                            fight.custom_stats?.find(stat => stat.name === info.key && stat.agent_id === fight.player1_id)?.value ?? ''
                                        )}
                                    </Td>
                                    <Td>
                                        {editFightId === fight.id ? (
                                            <Input
                                                value={editFightData.custom_stats?.find((stat) => stat.name === info.key && stat.agent_id === fight.player2_id)?.value ?? ''}
                                                onChange={(e) =>
                                                    setEditFightData({
                                                        ...editFightData,
                                                        custom_stats: editFightData.custom_stats?.some(stat => stat.name === info.key && stat.agent_id === fight.player2_id) ?
                                                            editFightData.custom_stats.map((stat) => {
                                                                if (stat.name === info.key && stat.agent_id === fight.player2_id) {
                                                                    return { ...stat, value: e.target.value };
                                                                } else {
                                                                    return stat;
                                                                }
                                                            }) :
                                                            [
                                                                ...editFightData.custom_stats,
                                                                {
                                                                    name: info.key,
                                                                    agent_id: fight.player2_id,
                                                                    value: e.target.value
                                                                }
                                                            ]
                                                    })
                                                }
                                                borderColor="cyan.600"
                                                focusBorderColor="cyan.400"
                                            />
                                        ) : (
                                            fight.custom_stats?.find(stat => stat.name === info.key && stat.agent_id === fight.player2_id)?.value ?? ''
                                        )}
                                    </Td>
                                </React.Fragment>
                            ))}
                            <Td>
                                {editFightId === fight.id ? (
                                    <HStack spacing={2}>
                                        <IconButton
                                            icon={<CheckIcon />}
                                            colorScheme="green"
                                            onClick={confirmEditing}
                                        />
                                        <IconButton
                                            icon={<CloseIcon />}
                                            colorScheme="red"
                                            onClick={cancelEditing}
                                        />
                                    </HStack>
                                ) : (
                                    <HStack spacing={2}>
                                        <IconButton
                                            icon={<EditIcon />}
                                            colorScheme="cyan"
                                            onClick={() => startEditing(fight)}
                                        />
                                        <IconButton
                                            icon={<DeleteIcon />}
                                            colorScheme="pink"
                                            onClick={() => handleDelete(fight.id)}
                                        />
                                    </HStack>
                                )}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default FightPanel;


FightPanel.propTypes = {
    selected: PropTypes.bool.isRequired
}