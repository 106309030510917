// SuccessModal.js
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalHeader, Text, Flex, Box } from '@chakra-ui/react';

const SuccessPopup = ( { isOpen, onClose, message } ) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose(); // Fermer automatiquement la modal après 2 secondes
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Succès !</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" alignItems="center" justifyContent="center" textAlign="center">
            <Box mb="4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="green" width="64px" height="64px">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </Box>
            <Text mb="4">{message}</Text>
            {/* <Button colorScheme="green" onClick={onClose}>Fermer</Button> */}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

SuccessPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
}

export default SuccessPopup;
